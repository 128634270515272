<template>
  <div class="verify-order-items">
    <b-card no-body>
      <b-card-header>
        <h4>Verify Order Items</h4>
      </b-card-header>
      <b-card-body>
        <div
          class="d-flex"
          style="column-gap: 10px;"
        >
          <div style="flex-basis: 200px;">
            <b-form-group label="Bucket No.">
              <b-form-input
                v-model="bucketNumber"
                autofocus
                type="text"
                placeholder="Enter Bucket No."
                @keyup.enter="fetchBucketInfo"
              />
            </b-form-group>
          </div>
          <div
            style="flex-basis: 200px;"
          >
            <b-form-group
              label="Bill No.:"
            >
              <custom-v-select
                v-model="billNumber"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="billNumbers"
                label="label"
                placeholder="Search Bill No."
                :clearable="true"
                @input="fetchBucketInfo"
              />
            </b-form-group>
          </div>
          <div>
            <b-button
              variant="outline-primary"
              class="form-inline-btn"
              :disabled="loading"
              @click="fetchBucketInfo"
            >
              Refresh
            </b-button>
          </div>
          <div
            style="flex-basis: 200px;"
            class="ml-2"
          >
            <b-form-group
              label="Search By"
            >
              <custom-v-select
                v-model="searchBy"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="searchByOptions"
                label="label"
                placeholder="Search By"
                :reduce="data => data.value"
                :clearable="false"
              />
            </b-form-group>
          </div>
          <div style="flex-basis: 300px;">
            <b-form-group
              label="Search Item"
            >
              <custom-v-select
                ref="searchItemField"
                v-model="searchItem"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invoiceItemOptions"
                label="label"
                placeholder="Search Item"
                :clearable="false"
                :disabled="attentionRequired"
                @input="onItemSelection"
              />
            </b-form-group>
          </div>
          <div style="flex-basis: 150px;">
            <b-form-group
              label="MRP"
            >
              <custom-v-select
                ref="mrpField"
                v-model="mrp"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="mrpOptions"
                label="label"
                placeholder="Search MRP"
                :clearable="false"
                :disabled="attentionRequired"
                @input="onMRPSelection"
              />
            </b-form-group>
          </div>
          <div style="flex-basis: 150px;">
            <b-form-group label="Quantity">
              <b-form-input
                ref="quantityField"
                v-model.number="quantity"
                type="number"
                placeholder="Enter Quantity"
                :disabled="attentionRequired || !searchItem || !mrp || updatingQty"
                @keyup.enter.stop="updatePickedQuantity"
              />
              <div
                v-if="!updatingQty && updateQtyStatus == 'error'"
                class="text-danger mt-50 font-weight-bold"
              >
                {{ updateQtyMessage }}
              </div>
            </b-form-group>
          </div>
          <div
            v-if="updatingQty || updateQtyStatus"
            class="form-inline-btn"
            style="width:25px;text-align: center;"
          >
            <b-spinner
              v-if="updatingQty"
              class="mt-75"
              label="Small Spinner"
              variant="primary"
              small
            />
            <feather-icon
              v-if="!updatingQty && updateQtyStatus == 'success'"
              icon="CheckIcon"
              color="green"
              size="25"
              class="mt-25"
            />
            <feather-icon
              v-if="!updatingQty && updateQtyStatus == 'error'"
              icon="XIcon"
              color="red"
              size="25"
              class="mt-25"
            />
          </div>
          <div class="ml-1">
            <b-button
              variant="outline-primary"
              class="mr-1 form-inline-btn"
              :disabled="!billNumber || !allItemsVerified || attentionRequired"
              @click="displayVerifyPickupModal = true"
            >
              Mark Verified
            </b-button>
          </div>
        </div>

        <div
          v-if="loading"
          class="text-center"
        >
          <b-spinner
            label="Small Spinner"
            variant="primary"
          />
        </div>

        <b-alert
          variant="danger"
          :show="!loading && errorMessage ? true : false"
        >
          <p
            class="alert-body"
          >
            {{ errorMessage }}
          </p>
        </b-alert>

        <div v-if="!loading && !errorMessage">
          <div class="mb-1">
            <rack-group-items
              :rg-reports="rackGroupReports"
              :read-only="true"
            />
          </div>

          <div class="mb-1">
            <b-alert
              variant="danger"
              :show="!loading && attentionRequired ? true : false"
            >
              <p
                class="alert-body"
              >
                Action for revision has to be taken first before updating status
              </p>
            </b-alert>
          </div>

          <div v-if="billNumber">
            <invoice-items-list
              title="Unverified Items"
              :items="unverifiedItems"
            />

            <invoice-items-list
              title="Verified Items"
              :items="verifiedItems"
            />
          </div>
        </div>
      </b-card-body>
    </b-card>

    <verify-pickup-modal
      v-if="displayVerifyPickupModal"
      :rg-report-ids="rackGroupReports.map(item => item.id)"
      :bucket-number="bucketNumber"
      @modal-closed="onVerifyModalClose"
      @verified="resetLocalData"
    />
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BAlert, BSpinner, VBTooltip, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import axios from 'axios'

import InvoiceItemsList from '@/components/Operations/VerifyOrderItems/InvoiceItemsList.vue'
import RackGroupItems from '@/components/Operations/RackGroupReports/RackGroupItems.vue'
import VerifyPickupModal from '@/components/Operations/VerifyOrderItems/VerifyPickupModal.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import dateMixin from '@/mixins/dateMixin'

import WS, { WS_ROOMS, WS_EVENTS } from '@/utils/ws'
import bus from '@/bus'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAlert,
    BSpinner,
    BFormGroup,
    BFormInput,
    BButton,
    CustomVSelect,
    VerifyPickupModal,
    InvoiceItemsList,
    RackGroupItems,
  },
  mixins: [dateMixin],
  data() {
    return {
      loading: false,
      errorMessage: null,
      bucketNumber: null,
      lastSubmitedBucketNumber: null,
      invoiceItems: [],
      searchBy: 'batch',
      searchByOptions: [
        {
          label: 'Batch',
          value: 'batch',
        },
        {
          label: 'Product Name',
          value: 'product',
        },
      ],
      searchItem: null,
      mrp: null,
      quantity: null,
      updatingQty: false,
      updateQtyStatus: null,
      updateQtyMessage: null,
      displayVerifyPickupModal: false,
      searchItemField: null,
      quantityField: null,
      billNumbers: [],
      billNumber: null,
      rackGroupReports: [],
      pendingRefresh: false,
    }
  },
  computed: {
    verifiedItems() {
      return this.invoiceItems.filter(item => item.quantity === item.picked_quantity)
    },
    unverifiedItems() {
      return this.invoiceItems.filter(item => item.quantity !== item.picked_quantity)
    },
    invoiceItemOptions() {
      const items = this.invoiceItems.map(item => item[this.searchBy])
      return [...new Set(items)]
    },
    mrpOptions() {
      const items = this.invoiceItems.map(item => item.mrp)
      return [...new Set(items)]
    },
    allItemsVerified() {
      return this.invoiceItems.every(item => item.quantity === item.picked_quantity)
    },
    attentionRequired() {
      return !!this.rackGroupReports.find(item => item.attention_required === true)
    },
  },
  watch: {
    searchBy() {
      this.searchItem = null
    },
    mrpOptions() {
      if (!this.mrpOptions.includes(this.mrp)) {
        this.mrp = null
      }
    },
  },
  created() {
    WS.joinRoom(WS_ROOMS.RG_REPORT_STATUS)
    bus.$on(WS_EVENTS.RG_REPORT_UPDATED, this.onRGReportUpdated)
  },
  destroyed() {
    WS.leaveRoom(WS_ROOMS.RG_REPORT_STATUS)
    bus.$off(WS_EVENTS.RG_REPORT_UPDATED, this.onRGReportUpdated)
  },
  methods: {
    fetchBucketInfo() {
      if (!this.bucketNumber) {
        this.errorMessage = 'Please enter valid bucket number!'
        this.resetLocalData()
        return
      }

      this.loading = true

      if (this.lastSubmitedBucketNumber !== this.bucketNumber) {
        this.billNumber = null
      }

      this.lastSubmitedBucketNumber = this.bucketNumber

      axios.get('operations/bucket-info', {
        params: {
          bucket_number: this.bucketNumber,
          bill_number: this.billNumber,
        },
      })
        .then(res => {
          const resData = res.data
          this.billNumbers = resData.bill_numbers
          this.billNumber = resData.bill_number
          this.rackGroupReports = resData.rack_group_reports
          this.invoiceItems = resData.invoice_items
          this.errorMessage = null
        }).catch(error => {
          this.errorMessage = error?.response?.data?.detail || 'Failed to fetch Bucket Info!'
          this.resetLocalData()
        }).finally(() => {
          this.loading = false
          this.searchItem = null
          this.quantity = null
          this.updateQtyMessage = null
          this.updateQtyStatus = null
        })
    },
    updatePickedQuantity() {
      if (this.quantity === 0 || !this.quantity) {
        this.updateQtyMessage = 'Please enter valid value'
        this.updateQtyStatus = 'error'
        return
      }

      this.updatingQty = true

      axios.post('operations/update-picked-quantity', {
        bucket_number: this.bucketNumber,
        bill_number: this.billNumber,
        search_by: this.searchBy,
        search_item: this.searchItem,
        mrp: this.mrp,
        quantity: this.quantity,
      })
        .then(res => {
          const serverInvoiceItem = res.data.invoice_item
          const invoiceItemIndex = this.invoiceItems.findIndex(item => item.id === serverInvoiceItem.id)
          this.invoiceItems.splice(invoiceItemIndex, 1, serverInvoiceItem)
          this.updateQtyMessage = null
          this.updateQtyStatus = 'success'
          this.onQuantityUpdated()
        }).catch(error => {
          const serverExtraQty = error?.response?.data?.extra_qty
          if (serverExtraQty) {
            this.updateQtyMessage = `Please return ${serverExtraQty} quantity, and try again!`
          } else {
            this.updateQtyMessage = error?.response?.data?.detail || 'Failed to update picked quantity!'
          }
          this.updateQtyStatus = 'error'
        }).finally(() => {
          this.updatingQty = false
        })
    },
    onItemSelection() {
      setTimeout(() => {
        this.focusField('mrpField')
      }, 150)
    },
    onMRPSelection() {
      setTimeout(() => {
        this.focusField('quantityField')
      }, 150)
    },
    onQuantityUpdated() {
      this.mrp = null
      this.quantity = null
      this.focusField('searchItemField')
    },
    focusField(field) {
      if (['quantityField'].includes(field)) {
        this.$refs[field].focus()
      } else {
        const input = this.$refs[field].$el.querySelector('input')
        if (input) {
          input.focus()
        }
      }
    },
    resetLocalData() {
      this.billNumbers = []
      this.billNumber = null
      this.rackGroupReports = []
      this.invoiceItems = []
      this.searchItem = null
      this.quantity = null
      this.updateQtyMessage = null
      this.updateQtyStatus = null
      this.pendingRefresh = false
      this.lastSubmitedBucketNumber = null
    },
    onRGReportUpdated(updatedItem) {
      const itemIndex = this.rackGroupReports.findIndex(item => item.id === updatedItem.id)
      if (itemIndex === -1) {
        return
      }

      if (this.displayVerifyPickupModal) {
        this.pendingRefresh = true
        return
      }

      this.fetchBucketInfo()
    },
    onVerifyModalClose() {
      this.displayVerifyPickupModal = false
      if (this.pendingRefresh) {
        this.fetchBucketInfo()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.form-inline-btn {
  margin-top: 2rem;
}
</style>
